<template>
  <div id="assignedItem">
    <div
      class="page-container-table"
    >
      <!--phần header-->
      <button-all-header
        :contentBtnAdd="'Thêm người dùng'"
        :arrayExcel="arrayExcel"
        :showBtnMultiDelete="showBtnMultiDelete"
        :hideDelete="false"
        :hideImportFile="false"
        :hideExportFile="false"
        :hideDowload="false"
        :hideAdd="false"
        class="mr-1"
        @search="search($event)"
      />

      <vue-good-table
        ref="user-table"
        style-class="vgt-table"
        :columns="columns"
        :rows="dataTable || []"
        :select-options="{
          enabled: false,
          vertialAlignTop: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
      >
        <!-- Slot: Table Row -->
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column:Mã loại báo cáo -->
          <span
            v-if="props.column.field === 'bussinessCode'"
            class="text-nowrap edit-report"
          >
            <b-badge
              pill
              class="light-code"
            >
              <span class="text-name">{{ $t(props.row.bussinessCode) }}</span>
            </b-badge>
          </span>

          <!-- Column:Hạn nộp báo cáo -->
          <span v-else-if="props.column.field === 'dateSend'">
            <span class="text-nowrap">{{ props.row.dateSend | formatDateToDDMM }}</span>
          </span>

          <!-- Column:Trạng thái -->
          <span v-else-if="props.column.field === 'submitReportStatusString'">
            <b-badge
              pill
              :variant="statusVariant(props.row.submitReportStatusString)"
              class="border-status"
            >
              <span>{{ props.row.submitReportStatusString }}</span>

            </b-badge>
          </span>
          <!-- Column:Chức năng -->
          <span v-else-if="props.column.field === 'actionFunction'">
            <feather-icon
              v-b-tooltip.hover.top="'Xem tài liệu'"
              icon="EyeIcon"
              size="18"
              @click="openSeePdf(props.row.id, 'PDF', props.row.isQrCode, props.row.name)"
            />
          </span>
        </template>
      </vue-good-table>

      <!-- pagination -->
      <my-pagination
        :totalItems="totalRecord"
        :currentPage="urlQuery.pageNumber"
        @pageClick="handlePageClick"
      />

      <!-- Xem file pdf -->
      <modal-see-pdf
        :id="modalSeePdf"
        :pdfsrc="pdfsrc"
        @downloadFile="downloadFilePdf"
      />
    </div>
  </div>
</template>

<script>
import { formatDateToDDMM } from '@core/utils/filter'
import { mapActions, mapGetters } from 'vuex'
import {
  BBadge, BRow, BCol, BFormGroup, VBTooltip,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import ModalSeePdf from './ModalSeePdf.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    VueGoodTable,
    MyPagination,
    ButtonAllHeader,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    ModalSeePdf,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  filters: {
    formatDateToDDMM,
  },
  data() {
    return {
      dataTable: [],
      columns: [
        {
          label: 'MÃ DOANH NGHIỆP',
          field: 'bussinessCode',
          sortable: false,
        },
        {
          label: 'TÊN DOANH NGHIỆP',
          field: 'bussiness',
          sortable: false,
        },
        {
          label: 'LOẠI BÁO CÁO',
          field: 'name',
          sortable: false,
        },
        {
          label: 'NGÀY NỘP',
          field: 'dateSend',
          sortable: false,
        },
        {
          label: 'Tình Trạng',
          field: 'status',
          sortable: false,
        },
        {
          label: 'TRẠNG THÁI',
          field: 'submitReportStatusString',
          sortable: false,
        },
        {
          label: 'NĂM',
          field: 'year',
          sortable: false,
        },
        {
          label: 'CHỨC NĂNG',
          field: 'actionFunction',
          sortable: false,
          width: '175px',
          tdClass: 'actiontd',
          thClass: 'actionth',
        },
      ],
      showBtnMultiDelete: false,
      userId: '',
      arrayExcel: null,
      totalRecord: 10,
      modalSeePdf: 'confirm-see-PDF',
      urlQuery: {
        bussinessId: '',
        year: null,
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      reportId: null,
      fileType: null,
      nameFile: null,
      pdfsrc: null,
      filePath: null,
    }
  },
  computed: {
    ...mapGetters('fiterYear', ['year', 'yearList']),
    statusVariant() {
      const statusColor = {
        'Chưa gửi duyệt': 'warning',
        'Đã gửi duyệt': 'primary',
        'Đã duyệt': 'success',
      }
      return status => statusColor[status]
    },
  },
  created() {
    this.fetchEnterpriseSubmited(this.urlQuery)
    this.getYear()
    this.dataYear = this.yearList
  },
  methods: {
    ...mapActions('fiterYear', ['getYear']),

    async openSeePdf(id, file, qrCode, name) {
      this.reportId = id
      this.fileType = file
      this.qrCode = qrCode
      this.nameFile = name
      const payload = {
        submitReportId: this.reportId,
        reportFormat: this.fileType,
        isQrCode: this.qrCode,
      }
      await axiosApiInstance({
        url: '/SubmitReport/export-report', // your url
        method: 'GET',
        responseType: 'blob', // important
        params: payload,
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        this.pdfsrc = url
      })
      this.$bvModal.show(this.modalSeePdf)
    },

    // Down file pdf
    downloadFilePdf() {
      const payload = {
        submitReportId: this.reportId,
        reportFormat: this.fileType,
        isQrCode: this.qrCode,
      }
      axiosApiInstance({
        url: '/SubmitReport/export-report', // your url
        method: 'GET',
        responseType: 'blob', // important
        params: payload,
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${this.nameFile}.pdf`) // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    },

    // Fetch data
    async fetchEnterpriseSubmited(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.ENTERPRISE_SUBMITED, {
        params: urlQuery,
      })
      this.dataTable = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },

    filterYear(val) {
      if (val != null) {
        this.urlQuery.year = val
        this.urlQuery.pageNumber = 1
        this.fetchEnterpriseSubmited(this.urlQuery)
      } else {
        this.urlQuery.year = ''
        this.urlQuery.pageNumber = 1
        this.fetchEnterpriseSubmited(this.urlQuery)
      }
    },

    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.fetchEnterpriseSubmited(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.fetchEnterpriseSubmited(this.urlQuery)
      }
    },

    handlePageClick(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchEnterpriseSubmited(this.urlQuery)
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

#assignedItem {
  .edit-report {
    cursor: pointer;
  }

  .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }

  .actiontd{
    text-align: center;
  }
  .actionth{
    text-align: center;
  }

  .border-status {
    border-radius: 4px;
  }

  .button-icon {
    .btn-component {
      width: 38px;
      height: 38px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 11px;
    }
  }

  .button-icon-custom {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
  }

  .search-input {
    width: 400px;
  }

  .button-import {
    .input-file {
      display: none;
    }
  }

  .btn-icon-file {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
    label {
      margin-bottom: unset !important;
    }
  }
}
</style>
