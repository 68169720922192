var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"assignedItem"}},[_c('div',{staticClass:"page-container-table"},[_c('button-all-header',{staticClass:"mr-1",attrs:{"contentBtnAdd":'Thêm người dùng',"arrayExcel":_vm.arrayExcel,"showBtnMultiDelete":_vm.showBtnMultiDelete,"hideDelete":false,"hideImportFile":false,"hideExportFile":false,"hideDowload":false,"hideAdd":false},on:{"search":function($event){return _vm.search($event)}}}),_c('vue-good-table',{ref:"user-table",attrs:{"style-class":"vgt-table","columns":_vm.columns,"rows":_vm.dataTable || [],"select-options":{
        enabled: false,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'bussinessCode')?_c('span',{staticClass:"text-nowrap edit-report"},[_c('b-badge',{staticClass:"light-code",attrs:{"pill":""}},[_c('span',{staticClass:"text-name"},[_vm._v(_vm._s(_vm.$t(props.row.bussinessCode)))])])],1):(props.column.field === 'dateSend')?_c('span',[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm._f("formatDateToDDMM")(props.row.dateSend)))])]):(props.column.field === 'submitReportStatusString')?_c('span',[_c('b-badge',{staticClass:"border-status",attrs:{"pill":"","variant":_vm.statusVariant(props.row.submitReportStatusString)}},[_c('span',[_vm._v(_vm._s(props.row.submitReportStatusString))])])],1):(props.column.field === 'actionFunction')?_c('span',[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Xem tài liệu'),expression:"'Xem tài liệu'",modifiers:{"hover":true,"top":true}}],attrs:{"icon":"EyeIcon","size":"18"},on:{"click":function($event){return _vm.openSeePdf(props.row.id, 'PDF', props.row.isQrCode, props.row.name)}}})],1):_vm._e()]}}])}),_c('my-pagination',{attrs:{"totalItems":_vm.totalRecord,"currentPage":_vm.urlQuery.pageNumber},on:{"pageClick":_vm.handlePageClick}}),_c('modal-see-pdf',{attrs:{"id":_vm.modalSeePdf,"pdfsrc":_vm.pdfsrc},on:{"downloadFile":_vm.downloadFilePdf}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }