var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container-table",attrs:{"id":"summary-training"}},[_c('button-all-header',{staticClass:"mr-1",attrs:{"contentBtnAdd":'Thêm người dùng',"arrayExcel":_vm.arrayExcel,"showBtnMultiDelete":_vm.showBtnMultiDelete,"hideDelete":false,"hideImportFile":false,"hideExportFile":false,"hideDowload":false,"hideAdd":false},on:{"search":function($event){return _vm.search($event)}}}),_c('vue-good-table',{ref:"user-table",attrs:{"style-class":"vgt-table","columns":_vm.columns,"rows":_vm.dataTable || [],"select-options":{
      enabled: false,
      vertialAlignTop: true,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'name')?_c('span',{staticClass:"text-nowrap edit-report"},[_c('b-badge',{staticClass:"light-code",attrs:{"pill":""}},[_c('span',{staticClass:"text-name"},[_vm._v(_vm._s(_vm.$t(props.row.name)))])])],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }