<template>
  <div>
    <my-tabs
      :listTabs="listTabs"
      defaultActive="activeTab"
      typeCss="active-full"
      @setActive="setActive"
    >
    </my-tabs>
    <div class="addBusiness-fix">
      <SummaryTraining
        v-if="isRendered('summary')"
        class="tab-pane"
        :class="{'active':activeTab==='summary'}"
      />
      <EnterpriseSubmited
        v-if="isRendered('business')"
        class="tab-pane"
        :class="{'active':activeTab==='business'}"
      />
    </div>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import MyTabs from '@/components/tabs/MyTabs.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '@/views/occupational-safety-health/supervise/constants/ConstantsApi'
import SummaryTraining from './components/SummaryTraining.vue'
import EnterpriseSubmited from './components/EnterpriseSubmited.vue'

export default {
  components: {
    MyTabs,
    SummaryTraining,
    EnterpriseSubmited,
  },
  data() {
    return {
      activeTab: null,
      detailId: '',
      detailData: {},
      listTabs: [
        {
          key: 'summary',
          title: 'Tổng hợp huấn luyện',
          icon: 'FolderIcon',
          isRendered: false,
        },
        {
          key: 'business',
          title: 'Doanh nghiệp đã nộp báo cáo huấn luyện',
          icon: 'BriefcaseIcon',
          isRendered: false,
        },
      ],
    }
  },

  watch: {
    $route(to, from) {
      if (to && to.params.type) {
        this.activeTab = to.params.type
        const tab = this.listTabs.find(x => x.key === this.activeTab)
        if (tab) tab.isRendered = true
      }
    },
  },

  created() {
    if (this.$route.params.type) {
      this.activeTab = this.$route.params.type
      const tab = this.listTabs.find(x => x.key === this.activeTab)
      if (tab) tab.isRendered = true
    }
    if (this.activeTab === null) {
      this.activeTab = 'summary'
      this.listTabs[0].isRendered = true
    }
  },
  methods: {
    isRendered(key) {
      const tab = this.listTabs.find(x => x.key === key)
      if (tab) return tab.isRendered
      return false
    },
    setActive(activeTab) {
      const active = this.activeTab
      this.activeTab = activeTab
      const tab = this.listTabs.find(x => x.key === activeTab)
      const indexTabNotActive = this.listTabs.findIndex(x => x.key === active)
      if (active !== this.activeTab) {
        if (tab) {
          tab.isRendered = true
          this.listTabs[indexTabNotActive].isRendered = false
        }
      }
    },
  },
}
</script>
