<template>
  <div
    id="summary-training"
    class="page-container-table"
  >
    <!--phần header-->
    <button-all-header
      :contentBtnAdd="'Thêm người dùng'"
      :arrayExcel="arrayExcel"
      :showBtnMultiDelete="showBtnMultiDelete"
      :hideDelete="false"
      :hideImportFile="false"
      :hideExportFile="false"
      :hideDowload="false"
      :hideAdd="false"
      class="mr-1"
      @search="search($event)"
    />

    <vue-good-table
      ref="user-table"
      style-class="vgt-table"
      :columns="columns"
      :rows="dataTable || []"
      :select-options="{
        enabled: false,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
    >
      <!-- Slot: Table Row -->
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span
          v-if="props.column.field === 'name'"
          class="text-nowrap edit-report"
        >
          <b-badge
            pill
            class="light-code"
          >
            <span class="text-name">{{ $t(props.row.name) }}</span>
          </b-badge>
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BBadge, BRow, BCol, BFormGroup, VBTooltip,
} from 'bootstrap-vue'

import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    VueGoodTable,
    BBadge,
    ButtonAllHeader,
  },
  data() {
    return {
      dataTable: [],
      columns: [
        {
          label: '',
          field: 'name',
          sortable: false,
        },
        {
          label: 'SỐ NGƯỜI ĐƯỢC HUẤN LUYỆN',
          field: 'total',
          sortable: false,
        },
        {
          label: 'SỐ NGƯỜI CẤP GIẤY/KHÔNG CẤP GIẤY',
          field: 'totalCerfiticate',
          sortable: false,
        },
      ],
      showBtnMultiDelete: false,
      userId: '',
      arrayExcel: null,
      totalRecord: 10,
      urlQuery: {
        year: null,
      },
    }
  },

  created() {
    this.fetchDSummaryTraining(this.urlQuery)
  },

  methods: {

    // Fetch data
    async fetchDSummaryTraining(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.SUMMARY_TRAINING, {
        params: urlQuery,
      })
      this.dataTable = data?.data
      this.$hideLoading()
    },

    filterYear(val) {
      if (val != null) {
        this.urlQuery.year = val
        this.urlQuery.pageNumber = 1
        this.fetchDSummaryTraining(this.urlQuery)
      } else {
        this.urlQuery.year = ''
        this.urlQuery.pageNumber = 1
        this.fetchDSummaryTraining(this.urlQuery)
      }
    },

    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.fetchDSummaryTraining(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.fetchDSummaryTraining(this.urlQuery)
      }
    },

    handlePageClick(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchDSummaryTraining(this.urlQuery)
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

#summary-training {
  .edit-report {
    cursor: pointer;
  }

  .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }

  .actiontd{
    text-align: center;
  }
  .actionth{
    text-align: center;
  }

  .border-status {
    border-radius: 4px;
  }

  .button-icon {
    .btn-component {
      width: 38px;
      height: 38px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 11px;
    }
  }

  .button-icon-custom {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
  }

  .search-input {
    width: 400px;
  }

  .button-import {
    .input-file {
      display: none;
    }
  }

  .btn-icon-file {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
    label {
      margin-bottom: unset !important;
    }
  }
}
</style>
